<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(v-if="$root.user.permissao != 0 || questionario.padrao == 1", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0 && questionario.padrao == 0", row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar('questionario')", data-vv-name="questionario",)
          v-card.elevation-1
            v-card-text
              v-layout(row, wrap)
                v-flex.md6.xs12
                  v-text-field(label="Nome", color="primary", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="Nome", type="text", v-model='questionario.nome')
                v-flex.md6.xs12
                  v-autocomplete(label="Selecione um tipo de questionário", :items="listTiposQuestionarios", :disabled="!listTiposQuestionarios.length", item-text="nome", item-value="id", color="gray", v-model="questionario.tipo_questionario_id", :loading="loadingTiposQuestionarios", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
                v-flex.md6.xs6
                  v-checkbox(label="Moeda", color="primary", data-vv-name="moeda", data-vv-as="Moeda",  v-model='questionario.moeda')
                v-flex.md6.xs6
                  v-text-field(label="Fator", color="primary", required, v-validate="'required'", :error-messages="errors.collect('fator')", data-vv-name="fator", data-vv-as="Fator", type="number", v-model='questionario.fator')
              v-layout(row, wrap)
                v-flex.xs12.text-xs-right
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

    v-layout(v-if="questionario && questionario.id && $root.user.permissao == 0 && questionario.padrao == 0", row, wrap)
      v-flex.xs12.mt-2
        v-card.elevation-1
          v-card-text
            v-layout(row, wrap)
              v-flex.xs12
                v-layout(row, wrap)
                  v-flex.xs4.mt-3
                    span.title Categorias
                  v-flex.xs4
                    v-switch(v-if="categorias && categorias.length", :false-value="0", :true-value="1", :color="filter.categoria_ativo ? 'success': ''", :label="filter.categoria_ativo ? 'Categorias ativas' : 'Categorias inativas'", v-model="filter.categoria_ativo")
                  v-flex.xs4.text-xs-right
                    v-btn(color="success", @click.native="openDialogCategoria(null)").white--text Cadastrar Categoria

      v-flex(v-if="questionario.id && categorias && categorias.length").xs12.mt-2
        v-card.elevation-
          v-card-text.elevation-0
            v-layout(row, wrap).mt-0
              v-flex.xs12
                v-data-table(:headers='headersCategorias', :items='listCategorias', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", item-key="$index")
                  template(slot='items', slot-scope='propsCategorias').elevation-0
                    td(width="5%").text-xs-center {{ propsCategorias.item.id }}
                    td.text-xs-left {{ propsCategorias.item.texto }}
                    td(width="15%").text-xs-center
                      v-tooltip(top)
                        v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small,  @click="openDialogCategoria(propsCategorias.item)")
                          v-icon edit
                        span Editar
                      v-tooltip(top)
                        v-btn(slot="activator", flat, icon, fab, dark, :color="propsCategorias.item.ativo ? 'red' : 'warning'", small, @click="changeAtivoCategoria(propsCategorias.item)")
                          v-icon {{ propsCategorias.item.ativo ? 'delete' : 'restore' }}
                        span {{ propsCategorias.item.ativo ? 'Desativar' : 'Ativar' }}

      v-flex.xs12.mt-2
        v-card.elevation-1
          v-card-text
            v-layout(row, wrap)
              v-flex.xs12
                v-layout(row, wrap)
                  span.title.mt-3.ml-3 Questões
                  v-spacer
                  v-autocomplete(label="Filtrar por categoria", :items="listCategorias", :disabled="!listCategorias.length", item-text="texto", item-value="id", color="primary", v-model="filter.categoria_questao_id", :loading="loadingCategorias", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
                  v-spacer
                  v-btn(color="success", @click.native="openDialogQuestao(null)").mt-3.white--text Cadastrar Questão

      v-flex(v-if="questionario.id").xs12.mt-2
        v-card.elevation-1
          v-card-text
            v-layout(row, wrap)
              v-layout(row, wrap).mt-4
                v-flex.xs12.pa-2
                  v-data-table(:headers='headers', :items='listQuestoes', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", item-key="$index")
                    template(slot='items', slot-scope='props').elevation-0
                      td(width="5%").text-xs-center {{ props.item.id }}
                      td.text-xs-left {{ props.item.texto }}
                      td.text-xs-left {{ props.item.categoria }}
                      td(width="15%").text-xs-center
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small,  @click="openDialogQuestao(props.item)")
                            v-icon edit
                          span Editar
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color="red", small, @click="deleteQuestao(props.item)")
                            v-icon delete_forever
                          span Excluir

    v-dialog(v-model="dialogQuestao", width="auto", scrollable, max-width="1000px", persistent, v-if="dialogQuestao")
      v-card
        v-card-title.grey.lighten-2
          v-layout(row, wrap)
            v-flex.md11
              span.headline {{ questaoAux.id ? 'Editar Questão' : 'Cadastrar Questão' }}
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="$validator.pause();  questaoAux = {}; dialogQuestao = false; tab = 0;").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        v-card-text
          v-tabs(v-model="tab", grow, slider-color="bt-primary").elevation-0
            v-tab(:key="0").primary--text Informações Gerais
            v-tab(:key="1", :disabled="!questaoAux.id").primary--text Respostas

            v-tab-item(:key="0").mt-2
              form(@submit.prevent="salvar('questao')", data-vv-name="questao", v-if="tab == 0")
                v-layout(row, wrap)
                  v-flex.xs12.md12
                    v-layout(row, wrap).mt-4
                      v-flex.xs6.md6.pa-1
                        v-text-field(color="primary", label='Texto', required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", type="text", v-model='questaoAux.texto', autofocus)
                      v-flex.xs6.md6.pa-1
                        v-autocomplete(label="Selecione uma categoria", :items="listCategorias", :disabled="!listCategorias.length", item-text="texto", item-value="id", color="gray", v-model="questaoAux.categoria_questao_id",:clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
                    v-layout(row, wrap).mt-4
                      v-flex.xs6.pa-1
                        .subheading.font-weight-bold Periodicidade
                        v-radio-group(v-model="questaoAux.periodicidade", row)
                          v-radio(:value="0", label="Não repete", color="bt-primary")
                          v-radio(:value="1", label="Semanal", color="bt-primary")
                          v-radio(:value="2", label="Mensal", color="bt-primary")
                      v-flex(v-if="questaoAux.periodicidade == 1 || questaoAux.periodicidade == 2").xs6.pa-1
                        v-text-field(color="gray", :label="questaoAux.periodicidade == 1 ? 'Periodo(semanas)' : questaoAux.periodicidade == 2 ? 'Período(meses)' : ''", required, v-validate="'required'", :error-messages="errors.collect('periodo')", data-vv-name="periodo", type="number", v-model='questaoAux.periodo')
                      v-flex.xs6.pa-1
                        .subheading.font-weight-bold Localização
                        v-radio-group(v-model="questaoAux.localizacao", row)
                          v-radio(:value="0", label="Não obter coordenadas", color="bt-primary")
                          v-radio(:value="1", label="Obter coordenadas", color="bt-primary")
                    v-layout(row, wrap)
                      v-flex.xs6.pa-1
                        v-textarea(color="primary", label='Dica', type="text", v-model='questaoAux.observacao')
                      v-flex.xs6.pa-1
                        .caption.font-weight-regular.pa-2 Imagem da Dica (Opcional)
                        crop-image(:defaultImage.sync="imagem", :height="500", :width="500", :circle="false", v-if="!imagem")
                        span(v-else).pa-3
                          img(:src="imagem", width="auto", height="300")
                          v-tooltip(top)
                            v-btn(icon, class="error", @click="removeFile(imagem)", :loading="loading", :disabled="loading", slot="activator")
                              v-icon delete
                            span REMOVER IMAGEM
                    v-layout(row, wrap)
                      v-flex.xs12.text-xs-right
                        v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

            v-tab-item(:key="1").mt-2.elevation-0
              v-layout(row, wrap)
                v-flex.xs4.md4.text-xs-center.mt-2
                v-flex.xs4.md4.text-xs-center.mt-2
                  v-switch(:false-value="0", :true-value="1", :color="filter.opcao_ativo ? 'success': ''", :label="filter.opcao_ativo ? 'Respostas ativas' : 'Respostas inativas'", v-model="filter.opcao_ativo")
                v-flex.xs4.md4.text-xs-right.mt-2
                  v-btn(color="success", @click.native="openDialogResposta(null)").white--text Cadastrar
              v-layout(row, wrap, v-if="questaoAux.id")
                v-flex.xs12.md12.pa-2
                  v-data-table(:headers="headersRespostas", :items='listOpcoes', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]").elevation-0
                    template(slot='items', slot-scope='props1').elevation-0
                      td(width="5%").text-xs-center {{ props1.item.id }}
                      td.text-xs-left {{ props1.item.texto }}
                      td(width="5%").text-xs-center {{ props1.item.min_anexos }}
                      td(width="5%").text-xs-center {{ props1.item.peso }}
                      td(width="20%").text-xs-center
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, @click="openDialogResposta(props1.item)")
                            v-icon edit
                          span Editar
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color="red", small, @click="changeAtivoOpcao(props1.item)")
                            v-icon {{ props1.item.ativo ? 'delete' : 'restore' }}
                          span {{ props1.item.ativo ? 'Desativar' : 'Ativar' }}

    v-dialog(v-model="dialogRespostas", width="auto", scrollable, max-width="500px", persistent, v-if="dialogRespostas")
      v-card
        form(@submit.prevent="salvar('resposta')", data-vv-name="resposta")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline {{ respostaAux.id ? 'Editar Resposta' : 'Cadastrar Resposta' }}
              v-flex.md1.text-xs-right
                v-tooltip(top)
                  v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogRespostas = false; respostaAux = {}").pa-0.mx-0.my-0.ma-0
                    v-icon close
                  span Fechar
          v-divider
          v-card-text
            v-layout(row, wrap)
              v-flex.xs12.md12.pa-1
                v-text-field(color="primary", label='Texto', required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", type="text", v-model='respostaAux.texto', autofocus)
              v-flex.xs4.md4.pa-1
                v-text-field(color="primary", label='Quantidade de Anexos', required, v-validate="'required'", :error-messages="errors.collect('min_anexos')", data-vv-name="min_anexos", type="number", v-model='respostaAux.min_anexos')
              v-flex.xs2.md2.pa-1
                v-text-field(color="primary", label='Peso', required, v-validate="'required'", :error-messages="errors.collect('peso')", data-vv-name="peso", type="number", v-model='respostaAux.peso')
              v-flex.xs6.md6.pa-1
                p(style="font-size: 12px; color: gray") Cor
                v-layout(row, wrap)
                    div(v-for="(color, index) in colors"
                            :key="index"
                            :style="{ width: '30px', height: '30px', margin: '0 3px', backgroundColor: color, cursor: 'pointer', borderRadius: '15px' }"
                            @click="mudarCorResposta(color)"
                            :class="{ 'selected': color === respostaAux.cor }")
          v-divider
          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.xs12.text-xs-right
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

    v-dialog(v-model="dialogCategoria", width="auto", scrollable, max-width="500px", persistent, v-if="questionario.id && dialogCategoria")
      v-card
        form(@submit.prevent="salvar('categoria')", data-vv-name="categoria")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline {{ categoriaAux && categoriaAux.id ? 'Editar Categoria' : 'Cadastrar Categoria' }}
              v-flex.md1.text-xs-right
                v-tooltip(top)
                  v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogCategoria = false; categoriaAux = {}").pa-0.mx-0.my-0.ma-0
                    v-icon close
                  span Fechar
          v-divider
          v-card-text
              v-layout(row, wrap)
                v-flex.xs12.md12.pa-1
                  v-text-field(color="primary", label='Texto', required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", type="text", v-model='categoriaAux.texto', autofocus)
          v-divider
          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.xs12.text-xs-right
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from "lodash";
import AccessLock from "@/components/AccessLock";
import CropImage from "@/components/CropImage";

export default {
    components: {
        CropImage,
        AccessLock,
    },
    $_veeValidate: {
        validator: "new",
    },
    data: () => ({
        categoriaAux: {},
        questaoAux: {},
        respostaAux: {},
        imagem: null,
        dialogQuestao: false,
        dialogCategoria: false,
        dialogRespostas: false,
        filter: {
            ativo: 1,
            categoria_ativo: 1,
            questaoAtivo: 1,
            categoria_questao_id: null,
            opcao_ativo: 1,
        },
        loading: false,
        questionario: {},
        listTiposQuestionarios: [],
        listLicencas: [],
        categorias: [],
        questoes: [],
        questao: {},
        categoria: {},
        resposta: {},
        loadingTiposQuestionarios: false,
        loadingLicencas: false,
        loadingCategorias: false,
        headers: [
            { text: "#", align: "center", value: "id" },
            { text: "Texo", align: "left", value: "texto" },
            { text: "Categoria", align: "left", value: "categoria" },
            { text: "Ações", align: "center", sortable: false },
        ],
        headersCategorias: [
            { text: "#", align: "center", value: "id" },
            { text: "Texto", align: "left", value: "texto" },
            { text: "Ações", align: "center", sortable: false },
        ],
        headersRespostas: [
            { text: "#", align: "center", value: "id" },
            { text: "Texto", align: "left", value: "texto" },
            { text: "Mínimo de  Anexos", align: "center", value: "min_anexos" },
            { text: "Peso", align: "center", value: "peso" },
            { text: "Ações", align: "center", sortable: false },
        ],
        tab: 0,
        colors: [
            "#F5C343",
            "#4caf50",
            "#f44336",
            "#FCFABC",
            "#F9D865",
            "#056CA0",
        ],
    }),
    computed: {
        list() {
            const vm = this;
            let questoes = _.cloneDeep(vm.questoes);
            // if (vm.filter.categoria_questao_id) {
            //   questoes = _.filter(questoes, { categoria_questao_id : vm.filter.categoria_questao_id });
            // } else if (this.filter.ativo) {
            //   questoes = _.filter(this.questoes, { ativo: this.filter.ativo });
            // }
            return questoes;
        },
        listCategorias() {
            const vm = this;
            return _.filter(this.categorias, {
                ativo: this.filter.categoria_ativo,
            });
        },
        listOpcoes() {
            const vm = this;
            return _.filter(vm.questaoAux.respostas, {
                ativo: this.filter.opcao_ativo,
            });
        },
        listQuestoes() {
            const vm = this;
            let questoes = _.cloneDeep(vm.questoes);
            if (!_.isNil(this.filter.categoria_questao_id)) {
                questoes = _.filter(vm.questoes, {
                    categoria_questao_id: vm.filter.categoria_questao_id,
                });
            }
            return questoes;
        },
    },
    mounted() {
        const vm = this;
        vm.getCategorias();
        if (vm.$route.params.id) {
            vm.getQuestionario(vm.$route.params);
        }
        vm.getTiposQuestionarios();
        // vm.getLicencas();
    },
    methods: {
        mudarCorResposta(corStr) {
            const vm = this;
            vm.respostaAux.cor = corStr;
            // console.log(respostaAux);
        },
        async save(questionario) {
            let user = this.$store.getters.user;
            const vm = this;
            try {
                const response = await vm.$axios.post(
                    "/questionario",
                    questionario,
                );
                questionario.id = response.data.id;
                vm.$router.push({
                    name: "QuestionarioEditar",
                    params: { id: response.data.id },
                });
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Questionário salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar o questionário. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async update(questionario) {
            const vm = this;
            try {
                if (questionario.LicencaId) delete questionario.LicencaId;
                if (questionario.TipoQuestionarioId)
                    delete questionario.TipoQuestionarioId;
                const response = await vm.$axios.put(
                    `/questionario/${questionario.id}`,
                    questionario,
                );
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Questionário salvo com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar o questionário. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async salvar(scope) {
            const vm = this;
            try {
                vm.loading = true;
                const result = await vm.$validator.validateAll(scope);
                if (!result) throw "Preencha todos os campos corretamente!";
                if (scope === "questionario") {
                    vm[vm.questionario.id ? "update" : "save"](vm.questionario);
                } else if (scope === "questao") {
                    if (vm.imagem && vm.imagem.indexOf("data:") > -1) {
                        const filename = await vm.uploadImagem(vm.imagem);
                        vm.questaoAux.dica_imagem = filename;
                    }
                    vm[vm.questaoAux.id ? "updateQuestao" : "saveQuestao"](
                        vm.questaoAux,
                    );
                } else if (scope === "categoria") {
                    vm[
                        vm.categoriaAux.id ? "updateCategoria" : "saveCategoria"
                    ](vm.categoriaAux);
                } else if (scope === "resposta") {
                    vm[vm.respostaAux.id ? "updateResposta" : "saveResposta"](
                        vm.respostaAux,
                    );
                }
                vm.$validator.reset();
            } catch (error) {
                window.getApp.$emit("APP_ALERT", { color: "red", text: error });
                vm.loading = false;
            }
        },

        async saveQuestao(questao) {
            const vm = this;
            try {
                vm.loading = true;
                questao.questionario_id = vm.questionario.id;
                const response = await vm.$axios.post("/questao", questao);
                vm.questaoAux = response.data;
                if (response.data && response.data.id) {
                    let questaoData = {
                        idQuestaoQuestionario: response.data.questionario_id,
                        id: response.data.id,
                        texto: response.data.texto,
                        categoria_questao_id:
                            response.data.categoria_questao_id,
                        categoria: _.find(vm.categorias, {
                            id: response.data.categoria_questao_id,
                        }).texto,
                        ativo: response.data.ativo,
                    };
                    vm.questoes.push(questaoData);
                    vm.tab = 1;
                    vm.loading = false;
                    window.getApp.$emit("APP_ALERT", {
                        color: "success",
                        text: "Questão salva com sucesso!",
                    });
                } else {
                    vm.loading = false;
                    throw false;
                }
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async updateQuestao(questaoAux) {
            const vm = this;
            try {
                delete questaoAux.CategoriaQuestaoId;
                const response = await vm.$axios.put(
                    `/questao/${questaoAux.id}`,
                    questaoAux,
                );
                const index = _.findIndex(vm.questoes, { id: questaoAux.id });
                const questoes = _.reject(vm.questoes, { id: questaoAux.id });
                response.data.categoria = _.find(vm.categorias, {
                    id: questaoAux.categoria_questao_id,
                }).texto;
                vm.questoes = [
                    ...questoes.slice(0, index),
                    response.data,
                    ...questoes.slice(index),
                ];
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Questão salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async saveCategoria(categoria) {
            const vm = this;
            try {
                vm.loading = true;
                categoria.questionario_id = vm.questionario.id;
                const response = await vm.$axios.post(
                    "/categoria-questao",
                    categoria,
                );
                vm.categorias.push(response.data);
                vm.categoriaAux = {};
                vm.dialogCategoria = false;
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Categoria salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a categoria. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async updateCategoria(categoriaAux) {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.put(
                    `/categoria-questao/${categoriaAux.id}`,
                    categoriaAux,
                );
                const index = _.findIndex(vm.categorias, {
                    id: categoriaAux.id,
                });
                const categorias = _.reject(vm.categorias, {
                    id: categoriaAux.id,
                });
                vm.categorias = [
                    ...categorias.slice(0, index),
                    response.data,
                    ...categorias.slice(index),
                ];
                _.map(vm.questoes, (q) => {
                    q.categoria = _.find(vm.categorias, {
                        id: q.categoria_questao_id,
                    }).texto;
                });
                vm.loading = false;
                vm.categoriaAux = {};
                vm.dialogCategoria = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Categoria salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar a categoria. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async saveResposta(resposta) {
            const vm = this;
            try {
                vm.filter.opcao_ativo = 0;
                vm.loading = true;
                resposta.questao_id = vm.questaoAux.id;
                const response = await vm.$axios.post(
                    "/opcoes-questao",
                    resposta,
                );
                if (vm.questaoAux.respostas) {
                    vm.questaoAux.respostas.push(response.data);
                } else {
                    vm.questaoAux.respostas = [];
                    vm.questaoAux.respostas.push(response.data);
                }
                vm.dialogRespostas = false;
                vm.filter.opcao_ativo = 1;
                vm.respostaAux = {};
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Resposta da questão salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a resposta da questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async updateResposta(respostaAux) {
            const vm = this;
            try {
                vm.filter.opcao_ativo = 0;
                const response = await vm.$axios.put(
                    `/opcoes-questao/${respostaAux.id}`,
                    respostaAux,
                );
                const index = _.findIndex(vm.categorias, {
                    id: respostaAux.id,
                });
                const respostas = _.reject(vm.questaoAux.respostas, {
                    id: respostaAux.id,
                });
                vm.questaoAux.respostas = [
                    ...respostas.slice(0, index),
                    response.data,
                    ...respostas.slice(index),
                ];
                vm.dialogRespostas = false;
                vm.respostaAux = {};
                vm.filter.opcao_ativo = 1;
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Resposta salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar a resposta. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async getQuestionario(params) {
            let vm = this;
            try {
                const response = await vm.$axios.get(
                    `/questionario/${params.id}`,
                    {
                        params: {
                            fields: "questoes_questionario,categorias_questoes",
                        },
                    },
                );
                vm.questionario = response.data || {};
                _.map(vm.questionario.questoes_questionario, (obj) => {
                    vm.questoes.push({
                        idQuestaoQuestionario: obj.id,
                        id: obj.questao.id,
                        texto: obj.questao.texto,
                        categoria_questao_id: obj.questao.categoria_questao.id,
                        categoria: obj.questao.categoria_questao.texto,
                        ativo: obj.ativo,
                    });
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async getTiposQuestionarios() {
            let vm = this;
            vm.loadingTiposQuestionarios = true;
            try {
                const response = await vm.$axios.get(`/tipo-questionario`);
                vm.loadingTiposQuestionarios = false;
                vm.listTiposQuestionarios = response.data.rows;
            } catch (error) {
                vm.loadingTiposQuestionarios = false;
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async getCategorias() {
            let vm = this;
            vm.loadingCategorias = true;
            try {
                const response = await vm.$axios.get(`/categoria-questao`, {
                    params: { questionario_id: vm.$route.params.id },
                });
                vm.loadingCategorias = false;
                vm.categorias = response.data.rows;
            } catch (error) {
                vm.loadingCategorias = false;
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },

        async openDialogQuestao(questao) {
            let vm = this;
            try {
                if (questao && questao.id) {
                    vm.questaoAux = _.cloneDeep(questao);
                    const response = await vm.$axios.get(
                        `/questao/${vm.questaoAux.id}`,
                        {
                            params: {
                                fields: "categoria_questao,opcoes_questao",
                            },
                        },
                    );
                    vm.questaoAux = response.data;
                    vm.questaoAux.categoria =
                        response.data.categoria_questao.texto;
                    vm.questaoAux.respostas = response.data.opcoes_questao;
                    vm.imagem = response.data.dica_imagem;
                    delete vm.questaoAux.opcoes_questao;
                }
                vm.dialogQuestao = true;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },

        async openDialogCategoria(categoria) {
            let vm = this;
            try {
                if (categoria && categoria.id) {
                    vm.categoriaAux = _.cloneDeep(categoria);
                    const response = await vm.$axios.get(
                        `/categoria-questao/${vm.categoriaAux.id}`,
                    );
                    vm.categoriaAux = response.data;
                }
                vm.dialogCategoria = true;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },

        async openDialogResposta(resposta) {
            let vm = this;
            try {
                if (resposta && resposta.id) {
                    vm.respostaAux = _.cloneDeep(resposta);
                    const response = await vm.$axios.get(
                        `/opcoes-questao/${vm.respostaAux.id}`,
                    );
                    vm.respostaAux = response.data;
                }
                vm.dialogRespostas = true;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },

        async getLicencas() {
            let vm = this;
            vm.loadingLicencas = true;
            try {
                const response = await vm.$axios.get(`/licenca`);
                vm.loadingLicencas = false;
                vm.listLicencas = response.data.rows;
            } catch (error) {
                vm.loadingLicencas = false;
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async changeAtivoCategoria(categoria) {
            const vm = this;
            try {
                const obj = _.cloneDeep(categoria);
                obj.ativo = obj.ativo ? 0 : 1;
                const response = await vm.$axios.put(
                    `/categoria-questao/${obj.id}`,
                    obj,
                );
                categoria.ativo = response.data.ativo;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a categoria. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },

        async changeAtivoOpcao(opcao) {
            const vm = this;
            try {
                const obj = _.cloneDeep(opcao);
                obj.ativo = obj.ativo ? 0 : 1;
                const response = await vm.$axios.put(
                    `/opcoes-questao/${obj.id}`,
                    obj,
                );
                opcao.ativo = response.data.ativo;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a categoria. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },

        async deleteQuestao(questao) {
            const vm = this;
            try {
                const response = await vm.$axios.delete(
                    `/questao/${questao.id}`,
                );
                if (response.data) {
                    vm.questoes = _.reject(vm.questoes, { id: questao.id });
                    window.getApp.$emit("APP_ALERT", {
                        color: "warning",
                        text: "Questão excluída  com sucesso!",
                    });
                } else {
                    throw false;
                }
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao exluir a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        removeFile(imagem, tipo) {
            const vm = this;
            if (vm.imagem && vm.imagem.indexOf("data:") > -1) {
                vm.imagem = null;
            } else {
                vm.deleteImagem(vm.imagem);
            }
        },
        async uploadImagem(imagem) {
            const vm = this;
            try {
                vm.loading = true;
                const blob = vm.$root.b64toBlob(imagem);
                const formData = new FormData();
                formData.append("file", blob, "imagem.jpg");
                const response = await vm.$axios.post(
                    "/questao-dica/arquivo",
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } },
                );
                vm.loading = false;
                return response.data.location;
            } catch (error) {
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Ocorreu um erro ao salvar a imagem. Tente novamente!",
                });
            }
        },
        async deleteImagem(imagem) {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.put("/questao-dica/arquivo", {
                    file: imagem,
                });
                vm.imagem = null;
                vm.questaoAux.dica_imagem = null;
                vm.loading = false;
                vm.updateQuestao(vm.questaoAux);
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao remover a imagem. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        customFilter(item, queryText, itemText) {
            const hasValue = (val) => (val != null ? val : "");
            const text = hasValue(item.nome || item.texto);
            const query = hasValue(queryText);
            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },
    },
};
</script>

<style scoped>
.selected {
    border: 3px solid green;
}
</style>
